import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import "./about-container.scss";

import { HashLink as Link } from "react-router-hash-link";

import officeImage from "../../images/office-hero.jpg";

const AboutContainer = () => {
  return (
    <>
      <Helmet>
        <title>
          About | SeidrLab Australia | App Development & AI Solutions with Data
          Science Expertise
        </title>
        <meta
          property="og:title"
          content="About | SeidrLab Australia | App Development & AI Solutions with Data Science Expertise"
        />
        <meta property="og:description" content="Meet the SeidrLab team" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://seidrlab.com/about`} />
      </Helmet>
      <div className="services-nav">
        <Navbar />
      </div>
      <div className="about-container">
        <div className="about-blurb">
          <h3>Pronounced SAY-der</h3>
          <p>
          </p>
          <div className="top-gradient-rule" />
          <img src={officeImage} alt="header" />
          <h4>
            Founded in 2020 in Sydney, Australia with clients across USA,
            Europe, UK and Australia.
          </h4>
        </div>
        <div className="top-gradient-rule" />
        <div className="beliefs">
          <div className="beliefs-header">
            <h2>We believe in</h2>
          </div>
          <div className="beliefs-content-stack">
            <div className="beliefs-content">
              <p>
                <span>A Good Team</span> - collaboration between skilled and
                experienced individuals.
              </p>
            </div>
            <div className="beliefs-content">
              <p>
                <span>Leaving a Legacy</span> - one to be proud of as a key
                partner to organisations.
              </p>
            </div>
            <div className="beliefs-content">
              <p>
                <span>Quality First</span> - 'Quality over Quantity' speaks for
                itself.
              </p>
            </div>
          </div>
        </div>
        <div className="executive-team">
          <h2>Founding Team</h2>
          <hr />
          <ul>
            <li>
              <span>Big Nerd</span>
              <div className="profile-headshot harry-image" />
              <h3>Harry Peppitt</h3>
              <p>Co-Founder / CEO</p>
            </li>
            <li>
              <span>Data Alchemist</span>
              <div className="profile-headshot ryan-image" />
              <h3>Ryan Clark</h3>
              <p>Co-Founder / CIO</p>
            </li>
          </ul>
        </div>
        <div className="profile-headshot board-of-advisors">
          <h2>Advisors</h2>
          <hr />
          <ul>
            <li>
              <div className="profile-headshot brian-image" />
              <h3>Brian Holt</h3>
              <p>
                <a href="https://www.snowflake.com/en/" target="_blank">
                  Senior Product Manager
                  <br /> @ Snowflake
                </a>
              </p>
            </li>
            <li>
              <div className="profile-headshot tom-image" />
              <h3>Tom Smithers</h3>
              <p>
                <a
                  href="https://www.linkedin.com/in/tomsmithers/"
                  target="_blank"
                >
                  Head of Consumer
                  <br /> @ Weatherzone
                </a>
              </p>
            </li>
            <li>
              <div className="profile-headshot oscar-image" />
              <h3>Oscar Peppitt</h3>
              <p>
                <a href="https://www.cutr.com/" target="_blank">
                  Founder and CEO
                  <br /> @ Cutr.ai
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className="we-are-hiring-banner">
          <h2>We're hiring</h2>
          <hr />
          <div className="jobs-container">
            <div className="job-role">
              <span>Data Scientist</span>
              <span>Remote / Sydney, Australia</span>
              <span>
                <Link to="/#contact">Get in touch</Link>
              </span>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
};

export default AboutContainer;
