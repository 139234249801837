import React from "react";
import { Helmet } from 'react-helmet';
import CTA from "../../components/CTA/CTAComponent";
import Services from "../../components/services/Services";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import SocialProof from "../../components/SocialProof";
import CurrentProjects from "../../components/CurrentProjects/CurrentProjects";

import "./home-container.scss";

const Home = () => {
  return (
    <div className="home-container">
      <Helmet>
        <title>SeidrLab Australia | App Development & AI Solutions with Data Science Expertise</title>
        <meta property="og:title" content='SeidrLab Australia | App Development & AI Solutions with Data Science Expertise' />
        <meta property="og:description" content='We build tech solutions that solve complex business challenges'/>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://seidrlab.com/`} />
      </Helmet>
      <Navbar />
      <Header />
      <SocialProof />
      <hr />
      <Services />
      <hr />
      <CurrentProjects />
      <hr />
      <CTA />
      <Footer />
    </div>
  );
};

export default Home;
