import React from "react";
import "./cta-component.scss";
import Form from "../FormComponent/Form";
import emailIcon from "../../../images/mail-icon.png";
import officeIcon from "../../../images/office-icon.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Cta = () => {
  const email = "hello@seidrlab.com";

  const copyEmail = (event) => {
    navigator.clipboard.writeText(email);
    event.preventDefault();
    alert();
  };

  const alert = () => {
    toast.success(`${email} copied!`, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return (
    <>
      <ToastContainer />
      <section className="cta-section">
        <div className="cta-container">
          <div id="contact">
            <div className="contact-us">
              <h4>Contact Us</h4>
              <h2>Chat to our friendly team.</h2>
              <div className="email-contact">
                <img src={emailIcon} alt="email-icon" />
                <div className="contact-content">
                  <h3>Email</h3>
                  <p className="content-subheading">
                    Our team is here to help.
                  </p>
                  <p className="email-and-address">
                    hello@seidrlab.com{" "}
                    <button className="button-primary" onClick={copyEmail}>
                      Copy
                    </button>
                  </p>
                </div>
              </div>
              {/* <hr /> */}
              <div className="office-contact">
                <img src={officeIcon} alt="office-pin" />
                <div className="contact-content">
                  <h3>Office</h3>
                  <p className="content-subheading">
                    Come say hello at our office.
                  </p>
                  <ul className="email-and-address">
                    <li>Desk Space</li>
                    <li>85 William St</li>
                    <li>Darlinghurst</li>
                    <li>Sydney, 2010</li>
                    <li>Australia</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="cta-content">
            <Form />
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
