import React from "react";
import "./social-proof-component.scss";
import theIconic from "../../images/client-logos/the-iconic-logo.png";
import domain from "../../images/client-logos/domain.com.au-logo.png";
import weatherZone from "../../images/client-logos/weatherzone-logo.png";
import newcastleTransport from "../../images/client-logos/newcastle-transport-logo.png";
import weTransfer from "../../images/client-logos/wetransfer-logo.png";
import carma from "../../images/client-logos/carma-logo.png";
import emersonCollective from "../../images/client-logos/emerson-collective-logo.png";

const SocialProof = () => {
  return (
    <>
      <div className="social-proof-section">
        <div className="social-proof-container">
          <p>Our clients</p>
          <ul>
            <li>
              <img src={theIconic} className="the-iconic" alt="the-iconic" />
            </li>
            <li>
              <img src={domain} className="domain" alt="domain" />
            </li>
            <li>
              <img src={weTransfer} className="wetransfer" alt="wetransfer" />
            </li>
            <li>
              <img
                src={newcastleTransport}
                className="newcastle-transport"
                alt="newcastle-transport"
              />
            </li>
            <li>
              <img
                src={weatherZone}
                className="weatherzone"
                alt="weatherzone"
              />
            </li>
            <li>
              <img src={carma} className="carma" alt="carma" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SocialProof;
