const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const LETTERS_ONLY_REGEX =  /^[a-zA-Z]+$/;
const NUMBERS_ONLY_REGEX =  /^\d+$/;

export function validateEmail(email) {
  return EMAIL_REGEX.test(email);
}

export function validatePassword(password) {
  return PASSWORD_REGEX.test(password);
}

export function validateLettersOnly(string) {
  return LETTERS_ONLY_REGEX.test(string);
}

export function validateNumbersOnly(string) {
  return NUMBERS_ONLY_REGEX.test(string);
}