import React from "react";
import "./header-component.scss";
import vector from "../../images/Vector.svg";
import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  return (
    <>
      <section className="header-section">
        <div className="header-container">
          <div className="content">
            <h1 className="header-title">
              We build tech solutions that solve complex business challenges.
            </h1>
            {/* <p className="header-text">
              Optimising your operations with custom software solutions.
            </p> */}
            <div className="actions">
              <Link to="/#services">
                <button className="btn-secondary">Learn More</button>
              </Link>
              <Link to="/#contact">
                <button className="btn-primary">Get In Touch</button>
              </Link>
            </div>
          </div>
          <br />
          <img src={vector} className="vector" alt="vector" />
        </div>
      </section>
    </>
  );
};

export default Header;
