import React from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import Home from "./routes/home/HomeContainer";

import ScrollToTop from "./components/ScrollToTop";
import MobileNavigation from "./components/MobileNavigation";
import DataServicesContainer from "./routes/services/dataServicesContainer";
import AboutContainer from "./routes/about/AboutContainer";

import "./fonts/TTFirsNeue-Light.otf";
import "./fonts/TTFirsNeue-Regular.otf";

const App = () => {
  return (
    <ChakraProvider>
      <div className="App">
        <div className="body">
          <ScrollToTop />
          <MobileNavigation></MobileNavigation>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutContainer />} />
            <Route path="/services/data" element={<DataServicesContainer />} />
          </Routes>
        </div>
      </div>
    </ChakraProvider>
  );
};

export default App;
