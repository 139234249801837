import { React, useEffect, useState } from "react";
import "../CTAComponent/cta-component.scss";
import { validateEmail } from "../../../lib/regex.js";
import Axios from "axios";
import { toast } from "react-toastify";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");

  const [isValidEmail, setIsValidEmail] = useState("");

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await validateCaptcha();
    if (!isValidEmail) {
      toast.error("Invalid email provided", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    Axios.post(
      "https://bzy3af08i7.execute-api.ap-southeast-2.amazonaws.com/prod/",
      {
        name,
        email,
        company,
        role,
        message,
        res,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((result) => {
        // console.log(result.data);
        window.dataLayer.push({
          event: "form_submit",
        });
        toast.success(`Success! We'll get back to you shortly.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Error, please try again`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    const contactForm = { name, email, company, role, message };
    setName("");
    setEmail("");
    setCompany("");
    setRole("");
    setMessage("");
  };

  const validateCaptcha = async () => {
    return new Promise((res, rej) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute("6Lc1A5kkAAAAAHtpb_BV6LgXcflM9KW0t-qgLGe_", {
            action: "formSubmit",
          })
          .then((token) => {
            return res(token);
          });
      });
    });
  };

  return (
    // <div className="placeholder-form-image">
    //   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.58946069274!2d151.21334031318852!3d-33.874469673112685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae140ad5bac5%3A0xd2d1e8bce78eb5c1!2sDesk%20Space!5e0!3m2!1sen!2sau!4v1670723241804!5m2!1sen!2sau" width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    // </div>
    <>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="input-container">
          <div className="input-field">
            <label>*First Name</label>
            <input
              maxLength={1000}
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder="First Name"
            />
          </div>
          <div className="input-field">
            <label>*Email</label>
            <input
              maxLength={1000}
              id="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="you@yourcompany.com"
            />
          </div>
          <div className="input-field">
            <label>Company</label>
            <input
              maxLength={1000}
              id="company"
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder="Your Company"
            />
          </div>
          <div className="input-field">
            <label>Role</label>
            <input
              maxLength={1000}
              id="role"
              type="text"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              placeholder="Your Role"
            />
          </div>
        </div>
        <div className="textbox-field">
          <label>*Message</label>
          <textarea
            maxLength={1000}
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            placeholder="Leave us a message..."
          />

          <button>Send Message</button>
        </div>
      </form>
    </>
  );
};

export default Form;
