import React, { useState } from "react";
import "./mobile-navigation-component.scss";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import Navbar from "../Navbar";

import { BsPlusLg, BsArrowRightShort } from "react-icons/bs";
import { AiOutlineMinus } from "react-icons/ai";

const MobileNavigationComponent = () => {
  const [toggleHideMobileNavigation, setToggleHideMobileNavigation] =
    useState(false);
  const [toggleData, setToggleData] = useState(true);

  const handleDataDropdownClick = () => {
    setToggleData(!toggleData);
  };

  const toggleMobileNav = () => {
    setToggleHideMobileNavigation(!toggleHideMobileNavigation);
    setToggleData(false);
  };

  return (
    <>
      <div
        className="mobile-navigation-component"
        onClick={() => toggleMobileNav()}
      />
      <div
        className={`mobile-navigation-component-menu ${
          toggleHideMobileNavigation ? "mobile-navigation-menu-active" : ""
        }`}
      >
        <div className="navbar-mobile">
          <Link to="/" onClick={() => toggleMobileNav()}>
            <Navbar />
          </Link>
        </div>
        <div className="close-menu-icon" onClick={() => toggleMobileNav()} />
        <ul className="menu-items">
          <div className="horizontal-rule" />
          <Link to="/" onClick={() => toggleMobileNav()}>
            <li>Home</li>
          </Link>
          <div className="horizontal-rule" />
          <div className="dropdown">
            <li className="text-link">
              <div
                className="dropdown-icon"
                onClick={() => handleDataDropdownClick()}
              >
                Data
                {!toggleData ? (
                  <BsPlusLg style={{ fontSize: "20px" }} />
                ) : (
                  <AiOutlineMinus style={{ fontSize: "20px" }} />
                )}
              </div>

              {toggleData ? (
                <div className="dropdown-menu">
                  <Link
                    to="/services/data/#data-experts"
                    onClick={() => toggleMobileNav()}
                  >
                    Data Experts
                  </Link>
                  <Link
                    to="/services/data/#modern-data-stack"
                    onClick={() => toggleMobileNav()}
                  >
                    Modern Data Stack
                  </Link>
                  <Link
                    to="/services/data/#data-analytics"
                    onClick={() => toggleMobileNav()}
                  >
                    Data Analytics
                  </Link>
                  <Link
                    to="/services/data/#marketing-analytics"
                    onClick={() => toggleMobileNav()}
                  >
                    Marketing Analytics
                  </Link>
                  <Link
                    to="/services/data/#data-strategy"
                    onClick={() => toggleMobileNav()}
                  >
                    Data Strategy <span>&</span> Governance
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </li>
          </div>
          <div className="horizontal-rule" />
          <div className="links-icon">
            <Link to="/blog" onClick={() => toggleMobileNav()}>
              <li>Blog</li>
              <BsArrowRightShort style={{ fontSize: "25px" }} />
            </Link>
          </div>
          <div className="horizontal-rule" />
          <div className="links-icon">
            <Link to="/about" onClick={() => toggleMobileNav()}>
              <li>About</li>
              <BsArrowRightShort style={{ fontSize: "25px" }} />
            </Link>
          </div>
          <div className="horizontal-rule" />
          <Link to="/#contact" onClick={() => toggleMobileNav()}>
            <li>
              <button>Get In Touch</button>
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
};

export default MobileNavigationComponent;
