import React from "react";
import "./navbar-component.scss";

import wordmark from "../../images/SeidrLab_WM-LightBlue-RGB.png";

import { HashLink as Link } from "react-router-hash-link";
import { useState } from "react";

const Navbar = () => {
  const [toggleData, setToggleData] = useState(false);
  const [toggleSoftware, setToggleSoftware] = useState(false);

  const handleDataDropdownClick = () => {
    setToggleData(!toggleData);
  };
  const handleSoftwareDropdownClick = () => {
    setToggleSoftware(!toggleSoftware);
  };

  return (
    <>
      <nav className="nav">
        <div id="home" />
        <div className="navbar-container">
          <div className="logo-img">
            <Link to="/">
              <img src={wordmark} alt="logo" />
            </Link>
          </div>
          <ul>
            <div className="links">
              <li className="text-link">
                <Link to="/">Home</Link>
              </li>
              <div className="dropdown">
                <li
                  className="text-link"
                  onMouseEnter={handleDataDropdownClick}
                  onMouseLeave={handleDataDropdownClick}
                >
                  <Link to="/services/data">Data</Link>
                  {toggleData ? (
                    <div className="dropdown-menu">
                      <Link to="/services/data/#data-experts">
                        Data Experts
                      </Link>
                      <Link to="/services/data/#modern-data-stack">
                        Modern Data Stack
                      </Link>
                      <Link to="/services/data/#data-analytics">
                        Data Analytics
                      </Link>
                      <Link to="/services/data/#marketing-analytics">
                        Marketing Analytics
                      </Link>
                      <Link to="/services/data/#data-strategy">
                        Data Strategy <span>&</span> Governance
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              </div>
              <li className="text-link">
                <Link to="/about">About</Link>
              </li>
            </div>
            <li>
              <Link to="/#contact">
                <button className="primary">Get In Touch</button>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
