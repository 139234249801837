import React from "react";
import "./footer-component.scss";
import mainLogo from "../../images/SeidrLab_ML-LightBlue-RGB.png";
import liIcon from "../../images/linkedin-logo.png";
import { HashLink as Link } from "react-router-hash-link";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="prefooter-container">
        <img src={mainLogo} className="main-logo" />
        <br />
        <div className="prefooter-links">
          <Link to="/">Home</Link>
          <Link to="/services/data">Data</Link>
          <Link to="/about">About</Link>
          <Link to="/#contact">Contact</Link>
        </div>
      </div>
      <div className="bottom-footer-container">
        <div className="bottom-footer-content">
          <p>© 2023 SeidrLab All rights reserved.</p>
          <a href="https://linkedin.com/company/seidrlab" target="_blank">
            <img src={liIcon} className="li-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
