import React from "react";
import vectorLeft from "../../../images/vector-left.svg";
import vectorRight from "../../../images/vector-right.svg";
import "../Services/services-component.scss";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { HashLink as Link } from "react-router-hash-link";

const ServicesContent = () => {
  return (
    <>
      <div className="info-container">
        <div className="vectors">
          <img src={vectorLeft} className="vector-left" alt="sparkles" />
          <img src={vectorRight} className="vector-right" alt="sparkles" />
        </div>
        <div className="service-content">
          <ul>
            <li>
              <Link to="/services/data">
                <div className="container-hover-change">
                  <div className="services-heading-and-icon">
                    <p className="services-heading">Data</p>
                    <ExternalLinkIcon color="#aca5ff" />
                  </div>
                  <p className="services-support-text">
                    Our team of data scientists understand business. They can
                    easily communicate how to do the most complex of tasks to
                    anyone, and how to apply creative data solutions to your
                    organisation.
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ServicesContent;
