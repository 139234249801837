import React from "react";
import ServicesContent from "../ServicesContent/ServicesContent";
import "./services-component.scss";

const Services = () => {
  return (
    <>
      <section className="services-section">
        <div id="services">
          <div className="title-container">
            <div className="title-content-container">
              <div className="heading-and-supporting-text">
                <div className="heading-and-subheading">
                  <p className="subheading">Services</p>
                  <h2 className="heading">
                    We find the right solutions to achieve your objectives.{" "}
                  </h2>
                  <h2 className="heading">
                    <span className="heading-gradient">
                      And if it doesn't exist, we'll build it.
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <ServicesContent />
        </div>
      </section>
    </>
  );
};

export default Services;
