import React from "react";
import { Helmet } from 'react-helmet';
import Footer from "../../../components/Footer";
import Navbar from "../../../components/Navbar";
import "./data-services-container.scss";

import { HashLink as Link } from "react-router-hash-link";

import dataHeroImg from "../../../images/data-hero-image.jpg";

const DataServicesContainer = () => {
  return (
    <>
      <Helmet>
        <title>Data Science, AI and Machine Learning Services | SeidrLab Australia</title>
        <meta property="og:title" content='Data Science, AI and Machine Learning Services | SeidrLab Australia' />
        <meta property="og:description" content='We build tech solutions that solve complex business challenges'/>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://seidrlab.com/services/data`} />
      </Helmet>
      <div className="services-nav">
        <Navbar />
      </div>
      <div className="services-page-container">
        <div className="services-page-header">
          <h2 className="services-header">Data Services</h2>
          <p className="services-desc">
            "Data is a precious thing and will last longer than the systems
            themselves."
            <p>
              <br />
              <i> - Tim Berners-Lee, Inventor of the World Wide Web.</i>
            </p>
          </p>
          <div className="top-gradient-rule" />
          <img src={dataHeroImg} alt="data-hero" />
          <div className="content-links-section">
            <div className="content-links">
              <ul>
                <Link to="/services/data#data-experts">
                  <li>Data Experts. On Demand.</li>
                </Link>
                <Link to="/services/data#modern-data-stack">
                  <li>Modern Data Stack</li>
                </Link>
                <Link to="/services/data#data-analytics">
                  <li>
                    Data Analytics <span>&</span> Data Science Projects
                  </li>
                </Link>
                <Link to="/services/data#marketing-analytics">
                  <li>Marketing Analytics</li>
                </Link>
                <Link to="/services/data#data-strategy">
                  <li>
                    Data Strategy <span>&</span> Governance
                  </li>
                </Link>
              </ul>
            </div>
          </div>
          <hr />
          <div className="services-content">
            <div className="services-content-section">
              <div className="services-content-heading">
                <h2 id="data-experts">
                  <span>Data Experts. On Demand.</span> Good data people are
                  hard to find and even harder to retain. SeidrLab offer the
                  right skills when you need them.
                </h2>
              </div>
              <div className="services-content-copy">
                <h3>All the skills</h3>
                <p>
                  Today's complete data team requires knowledge in data
                  analytics, data science, data engineering, data architecture,
                  analytics engineers, marketing analytics, solution
                  architecture, dev ops, data ops, ML ops and more. We can wear
                  the right hat on the right day and get things done.
                </p>
                <h3>Knowledge continuity</h3>
                <p>
                  Do you need to bridge a gap with recent leavers? Our team can
                  step in and provide the continuity while you hire, for less
                  than the cost of an FTE. Let us maintain the critical
                  information flow while you find the right person.
                </p>
                <h3>No contract lock in</h3>
                <p>
                  If you love something, you've got to let it go. We get that
                  you want to fly on your own and when that day comes, we'll
                  help you build the jetpack.
                </p>
                <h3>Hassle-free relationships</h3>
                <p>
                  No hiring challenges, No HR issues, No training costs, No
                  on-costs, No retention management, No key person risk, and
                  we're really great people.
                </p>
                <h3>We are partners, not freelancers</h3>
                <p>
                  We learn your business and bring our collective experience
                  with your organisation and its specific challenges. This
                  allows us to work as a cog in your machine, not a competing
                  force outside of it.
                </p>
                <h3>We make financial sense</h3>
                <p>
                  ROI and costs are important, by sharing experts we can provide
                  cost effective skills when you need them but not when you
                  don't.
                </p>
              </div>
            </div>
            <hr />
            <div className="services-content-section">
              <div className="services-content-heading">
                <h2 id="modern-data-stack">
                  <span>Modern Data Stack.</span> There are thousands of tools
                  across an ever changing landscape of best practice in the
                  modern data stack.
                </h2>
              </div>
              <div className="services-content-copy">
                <h3>Size doesn't matter</h3>
                <p>
                  No project is too small or too big, we can implement a single
                  database migration or implement a full stack with custom
                  software.
                </p>
                <h3>We are tool agnostic</h3>
                <p>
                  We find the right data tools that are right for your
                  organisation.
                </p>
                <h3>Open source</h3>
                <p>
                  We use open source or free tools when appropriate for your
                  organisation.
                </p>
                <h3>Sort the hype from the essentials</h3>
                <p>
                  SaaS, Data Lake, Lakehouse, Low Code, Data Vault, CDP, Single
                  Customer View, Data Fabric, Data Mesh? Do you need them, is it
                  just marketing? Why would you use one or not? We know the
                  answer and when they are appropriate. Let our experience in
                  the industry guide you through the maze of products.
                </p>
                <h3>Support for the whole process</h3>
                <p>
                  Changes in your data maturity or tools take time to bed in.
                  Change management is an important part of the project.
                  SeidrLab is here to support the implementation and the change,
                  carefully incorporating any skills or processes change into
                  the project
                </p>
                <h3>Bespoke if required</h3>
                <p>
                  If the right tool doesn't exist or doesn't match your
                  requirements, we can build it.
                </p>
                <h3>No sales, just answers</h3>
                <p>
                  We have tested tools and spoken to salespeople so you don't
                  have to. We know where to look for weaknesses in a solution so
                  you can get it right the first time.
                </p>
              </div>
            </div>
            <hr />
            <div className="services-content-section">
              <div className="services-content-heading">
                <h2 id="data-analytics">
                  <span>
                    Data Analytics <span>&</span> Data Science Projects.
                  </span>{" "}
                  Analytics and data science projects often become more
                  challenging than they first appear.
                </h2>
              </div>
              <div className="services-content-copy">
                <h3>
                  Data analytics <span>&</span> data science projects
                </h3>
                <p>
                  Analytic and data science projects deliver business results
                  and require technical staff that understand business needs. At
                  SeidrLab we are data people that get business requirements.
                </p>
                <h3>Designed for reusability</h3>
                <p>
                  Analytical projects are an investment and solutions need to be
                  repeatable and refreshable without high operational overhead.
                  We design with automation, repeatability and observability in
                  mind.
                </p>
                <h3>Formal training with business experience</h3>
                <p>
                  Our data scientists have completed formal training with
                  experience implementing projects in real businesses.
                </p>
                <h3>We don't skip the small stuff</h3>
                <p>
                  Successful data projects require whole project management,
                  workshops, investigative analytics, documentation, data
                  quality checking, result measurement, stakeholder
                  communication and project management.
                </p>
                <h3>Good practice, not fancy models</h3>
                <p>
                  Good process, data understanding, quality checking and
                  understanding the business value get results. We love a deep
                  neural network as much as the next data scientist but using
                  the good practice trumps using current popular one every time
                </p>
                <h3>Get your environment right</h3>
                <p>
                  Want to set your business up for multiple analytics projects?
                  SeidrLab can design and implement the framework and tools to
                  allow controlled freedom for your internal team to build your
                  analytics Centre of Excellence with confidence.
                </p>
              </div>
            </div>
            <hr />
            <div className="services-content-section">
              <div className="services-content-heading">
                <h2 id="marketing-analytics">
                  <span>Marketing Analytics.</span> Measuring and understanding
                  customer behaviour to increase ROI and performance.
                </h2>
              </div>
              <div className="services-content-copy">
                <h3>Understand your customers</h3>
                <p>
                  Whether you need behavioural analytics or just want to know
                  who they are, we can help you better understand your customer
                  and how to get to them.
                </p>
                <h3>Cross-platform and attribution solutions</h3>
                <p>
                  Figure out where your customers are coming from, where they're
                  going and where you're spending to acquire them.
                </p>
                <h3>Reporting Automation</h3>
                <p>
                  Automate the repetitive tasks and give the time back to your
                  team. Let them focus on the high value activities instead.
                </p>
                <h3>All the technical stuff</h3>
                <p>
                  We work with marketing, product and engineering and speak all
                  of their languages. Communication and technical know how is
                  key.
                </p>
                <h3>Dashboards for every use case</h3>
                <p>
                  Need dashboards? We can build them. On any platform, in any
                  vertical.
                </p>
                <h3>A MarTech stack that works for you</h3>
                <p>
                  Sometimes you need that expensive CDP solution and sometimes
                  you just need a pivot table. We take the hassle out of
                  figuring out what tools you need to achieve your goals.
                </p>
              </div>
            </div>
            <hr />
            <div className="services-content-section">
              <div className="services-content-heading">
                <h2 id="data-strategy">
                  <span>
                    Data Strategy <span>&</span> Data Governance.
                  </span>{" "}
                  Good data strategy and governance allows an organisation to be
                  confident in the processes, protection and compliance.
                </h2>
              </div>
              <div className="services-content-copy">
                <h3>A plan to suit your needs</h3>
                <p>
                  There is no one size fits all strategy or governance. Each
                  organisation needs to look at its data assets, maturity level
                  and intentions to find the right fit.
                </p>
                <h3>Good strategy = good work = staff retention</h3>
                <p>
                  Enabling teams with effective strategy and governance allows
                  them to focus on their roles and deliver for the business.
                  This results in faster delivery of solutions, happier staff,
                  and an organisation confident in its direction.
                </p>
                <h3>Create a roadmap to keep everyone working together</h3>
                <p>
                  With a good strategy and roadmap you can ensure that projects
                  succeed by not missing steps, you can't 'data science' until
                  you have quality data. Make sure you set yourself up for
                  success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="bottom-hr" />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </>
  );
};

export default DataServicesContainer;
