import React from "react";
import "./current-projects-container.scss";

const CurrentProjects = () => {
  return (
    <div className="current-projects-container">
      <h2>Current Projects</h2>
      <div className="projects-content-container">
        <div className="projects-cards">
          <div className="card-image carma-thumbnail" />
          <div className="projects-content">
            <h4>Carma</h4>
            <p>Customer ecosystem tracking and analytics for product team.</p>
          </div>
        </div>
        <div className="projects-cards">
          <div className="card-image vc-thumbnail" />
          <div className="projects-content">
            <h4>VC firm in Silicon Valley</h4>
            <p>Streamlining and automating financial and fund modelling.</p>
          </div>
        </div>
        <div className="projects-cards">
          <div className="card-image weatherzone-thumbnail" />
          <div className="projects-content">
            <h4>Weatherzone</h4>
            <p>Infrastructure and analytics build for consumer products.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentProjects;
